import axios from 'axios';
import { getIdToken } from '@/utils/kkpp';

// ユーザ情報取得
export const getUserMe = (): any => {
  const xbaUrl = localStorage.getItem('xba');
  const token = getIdToken();

  if (!token || token === 'false' || !xbaUrl) {
    return null;
  }

  return axios({
    method: 'get',
    url: xbaUrl + '/api/v1/user/me',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      Authorization: token
    }
  }).catch(
    function () {
      console.log("XBAのAPIを呼び出す時、エラーが発生しました。");
    }
  );
};
