/* eslint-disable */

export default async function ({ $auth }) {
  if (!$auth.loggedIn) {
    const logout = localStorage.getItem('logout');
    if (logout) {
      window.location.replace('/ba');
    } else {
      localStorage.clear();
      console.log('localstorage clear');
      location.href = `/login`;
    }
  }
}
