import axios from 'axios';
import { getIdToken } from '@/utils/kkpp';

const service = axios.create({});

// Request interceptors
service.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';

    const token = getIdToken();

    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    return res;
  },
  (error) => {
    if (error.response) {
      // 認証エラー
      if (error.response.status === 401) {
        location.href = '/ba';
        return null;
      } else {
        return Promise.reject(error.response);
      }
    }
    return null;
  }
);

export default service;
